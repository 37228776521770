// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';

import { listCareSessions } from 'api/patient';
import * as alertSnackbar from 'utils/snackbar-wrapper';

// ----------------------------------------------------------------------

const initialState = {
    careSessionList: {
        error: false,
        loaded: false,
        careSessions: [],
        careMetadata: {
            durationSeconds: {
                total: 0,
                ccm: 0,
                bhi: 0,
                rpm: 0,
                pcm: 0
            },
            counts: {
                total: 0,
                ccm: 0,
                bhi: 0,
                rpm: 0,
                pcm: 0
            },
            pageSize: 20
        },
        assessmentDefinitions: []
    }
};

const slice = createSlice({
    name: 'careSessionList',
    initialState,
    reducers: {
        setCareSessionListLoading(state) {
            state.careSessionList.loaded = false;
        },
        setCareSessionList(state, action) {
            state.careSessionList.error = false;
            state.careSessionList.loaded = true;
            state.careSessionList.careSessions = action.payload.careSessions;
            state.careSessionList.careMetadata = action.payload.careMetadata;
            state.careSessionList.assessmentDefinitions = action.payload.assessmentDefinitions;
        },
        setCareSessionListError(state) {
            dispatch(alertSnackbar.error('Failed to fetch care sessions'));
            state.careSessionList.error = true;
        },

        resetState(state) {
            state.careSessionList = initialState.careSessionList;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function resetState() {
    dispatch(slice.actions.resetState());
}

/*
params.from as .toDateString() format example: 'Tue Oct 11 2022'
params.to as .toDateString() format example: 'Tue Oct 11 2022'
if the filter is not selected it will be undefined
currently they are only used together and no one way dates are supported
*/
export function getCareSessionList(patientId, params) {
    return async () => {
        try {
            dispatch(slice.actions.setCareSessionListLoading());
            // send as unix timestamps
            const apiParams = {
                ...params,
                to: params.to ? new Date(params.to).getTime() + 1000 * 60 * 60 * 24 : undefined,
                from: params.from ? new Date(params.from).getTime() : undefined
            };
            const response = await listCareSessions(patientId, apiParams);
            dispatch(slice.actions.setCareSessionList(response.data));
        } catch (error) {
            dispatch(slice.actions.setCareSessionListError());
        }
    };
}
