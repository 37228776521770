import { createSlice } from '@reduxjs/toolkit';

import { dispatch } from '../index';

import { getEnrollmentStats } from 'api/enrollment';
import { listPatients } from 'api/patient';

// ----------------------------------------------------------------------

const initialState = {
    patients: {
        error: null,
        loaded: false,
        data: []
    },
    enrollment: {
        stats: {
            bhi: 0,
            ccm: 0,
            rpm: 0,
            pcm: 0
        },
        error: null,
        loaded: false
    }
};

const slice = createSlice({
    name: 'patientList',
    initialState,
    reducers: {
        setPatientListError(state, action) {
            state.patients.error = action.payload;
            state.patients.loaded = true;
        },

        getPatientsSuccess(state, action) {
            state.patients.data = action.payload;
            state.patients.loaded = true;
        },

        setEnrollmentStats(state, action) {
            state.enrollment.stats = action.payload;
            state.enrollment.loaded = true;
            state.enrollment.error = null;
        },
        setEnrollmentStatsError(state, action) {
            state.enrollment.loaded = true;
            state.enrollment.error = action.payload;
        },

        resetState(state) {
            state.patients = initialState.patients;
            state.enrollment = initialState.enrollment;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function resetState() {
    return dispatch(slice.actions.resetState());
}

export function getPatients() {
    return async () => {
        try {
            const response = await listPatients();
            dispatch(slice.actions.getPatientsSuccess(response.data.patients));
        } catch (error) {
            dispatch(slice.actions.setPatientListError(error));
        }
    };
}

export function fetchEnrollmentStats(clinicId) {
    return async () => {
        try {
            const response = await getEnrollmentStats(clinicId);
            dispatch(slice.actions.setEnrollmentStats(response.data.enrollmentStats));
        } catch (error) {
            dispatch(slice.actions.setEnrollmentStatsError(error));
        }
    };
}
