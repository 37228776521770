// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';

import { getVitalsDataMeta, getVitalsData, getVitalsDevices } from 'api/vitalsData';
import { VITALS_DATA_TYPES } from 'utils/vitalsData';

// ----------------------------------------------------------------------

const initialState = {
    meta: {
        loaded: false,
        error: false,
        data: {
            BLOOD_GLUCOSE: false,
            BLOOD_PRESSURE: false,
            PULSE_OXIMETER: false,
            WEIGHT: false
        }
    },
    selectedVitalsDataType: null,
    vitalsData: {
        BLOOD_GLUCOSE: {
            loaded: false,
            error: false,
            data: []
        },
        BLOOD_PRESSURE: {
            loaded: false,
            error: false,
            data: []
        },
        PULSE_OXIMETER: {
            loaded: false,
            error: false,
            data: []
        },
        WEIGHT: {
            loaded: false,
            error: false,
            data: []
        }
    },
    vitalsDevices: {
        loaded: false,
        error: false,
        data: []
    }
};

const slice = createSlice({
    name: 'vitalsData',
    initialState,
    reducers: {
        setVitalsMeta(state, action) {
            state.meta.loaded = true;
            state.meta.error = false;
            state.meta.data = action.payload;
        },
        setVitalsMetaError(state, action) {
            state.meta.loaded = true;
            state.meta.error = action.payload;
        },
        setBloodGlucoseVitalsData(state, action) {
            state.vitalsData.BLOOD_GLUCOSE.loaded = true;
            state.vitalsData.BLOOD_GLUCOSE.error = false;
            state.vitalsData.BLOOD_GLUCOSE.data = action.payload;
        },
        setBloodGlucoseVitalsDataError(state, action) {
            state.vitalsData.BLOOD_GLUCOSE.loaded = true;
            state.vitalsData.BLOOD_GLUCOSE.error = action.payload;
        },
        setBloodPressureVitalsData(state, action) {
            state.vitalsData.BLOOD_PRESSURE.loaded = true;
            state.vitalsData.BLOOD_PRESSURE.error = false;
            state.vitalsData.BLOOD_PRESSURE.data = action.payload;
        },
        setBloodPressureVitalsDataError(state, action) {
            state.vitalsData.BLOOD_PRESSURE.loaded = true;
            state.vitalsData.BLOOD_PRESSURE.error = action.payload;
        },
        setPulseOximeterVitalsData(state, action) {
            state.vitalsData.PULSE_OXIMETER.loaded = true;
            state.vitalsData.PULSE_OXIMETER.error = false;
            state.vitalsData.PULSE_OXIMETER.data = action.payload;
        },
        setPulseOximeterVitalsDataError(state, action) {
            state.vitalsData.PULSE_OXIMETER.loaded = true;
            state.vitalsData.PULSE_OXIMETER.error = action.payload;
        },
        setWeightVitalsData(state, action) {
            state.vitalsData.WEIGHT.loaded = true;
            state.vitalsData.WEIGHT.error = false;
            state.vitalsData.WEIGHT.data = action.payload;
        },
        setWeightVitalsDataError(state, action) {
            state.vitalsData.WEIGHT.loaded = true;
            state.vitalsData.WEIGHT.error = action.payload;
        },
        setSelectedVitalsType(state, action) {
            state.selectedVitalsDataType = action.payload;
        },

        setVitalsDevices(state, action) {
            state.vitalsDevices.loaded = true;
            state.vitalsDevices.error = false;
            state.vitalsDevices.data = action.payload;
        },
        setVitalsDevicesError(state, action) {
            state.vitalsDevices.loaded = true;
            state.vitalsDevices.error = action.payload;
        },

        resetState(state) {
            state.meta = initialState.meta;
            state.vitalsData = initialState.vitalsData;
            state.selectedVitalsDataType = initialState.selectedVitalsDataType;
            state.vitalsDevices = initialState.vitalsDevices;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function resetState() {
    return dispatch(slice.actions.resetState());
}

export function getMeta(patientId) {
    return async () => {
        const response = await getVitalsDataMeta(patientId);
        if (response.status === 200) {
            dispatch(slice.actions.setVitalsMeta(response.data.meta));
        } else {
            dispatch(slice.actions.setVitalsMetaError(response.message));
        }
    };
}

export function getBloodGlucoseVitalsData(patientId) {
    return async () => {
        const response = await getVitalsData(patientId, VITALS_DATA_TYPES.BLOOD_GLUCOSE);
        if (response.status === 200) {
            dispatch(slice.actions.setBloodGlucoseVitalsData(response.data.vitalsData));
        } else {
            dispatch(slice.actions.setBloodGlucoseVitalsDataError(response.message));
        }
    };
}

export function getBloodPressureVitalsData(patientId) {
    return async () => {
        const response = await getVitalsData(patientId, VITALS_DATA_TYPES.BLOOD_PRESSURE);
        if (response.status === 200) {
            dispatch(slice.actions.setBloodPressureVitalsData(response.data.vitalsData));
        } else {
            dispatch(slice.actions.setBloodPressureVitalsDataError(response.message));
        }
    };
}

export function getPulseOximeterVitalsData(patientId) {
    return async () => {
        const response = await getVitalsData(patientId, VITALS_DATA_TYPES.PULSE_OXIMETER);
        if (response.status === 200) {
            dispatch(slice.actions.setPulseOximeterVitalsData(response.data.vitalsData));
        } else {
            dispatch(slice.actions.setPulseOximeterVitalsDataError(response.message));
        }
    };
}

export function getWeightVitalsData(patientId) {
    return async () => {
        const response = await getVitalsData(patientId, VITALS_DATA_TYPES.WEIGHT);
        if (response.status === 200) {
            dispatch(slice.actions.setWeightVitalsData(response.data.vitalsData));
        } else {
            dispatch(slice.actions.setWeightVitalsDataError(response.message));
        }
    };
}

export function setBloodGlucoseDataTypeAsSelected() {
    dispatch(slice.actions.setSelectedVitalsType(VITALS_DATA_TYPES.BLOOD_GLUCOSE));
}

export function setBloodPressureDataTypeAsSelected() {
    dispatch(slice.actions.setSelectedVitalsType(VITALS_DATA_TYPES.BLOOD_PRESSURE));
}

export function setPulseOximeterDataTypeAsSelected() {
    dispatch(slice.actions.setSelectedVitalsType(VITALS_DATA_TYPES.PULSE_OXIMETER));
}

export function setWeightDataTypeAsSelected() {
    dispatch(slice.actions.setSelectedVitalsType(VITALS_DATA_TYPES.WEIGHT));
}

export function getVitalsDevicesList(patientId) {
    return async () => {
        const response = await getVitalsDevices(patientId);
        if (response.status === 200) {
            dispatch(slice.actions.setVitalsDevices(response.data.devices));
        } else {
            dispatch(slice.actions.setVitalsDevicesError(response.message));
        }
    };
}
