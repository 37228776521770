import axios from 'utils/axios';

// eslint-disable-next-line no-unused-vars
export async function getCarePlanMeta(patientId, carePlanId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/care-plan/${carePlanId}/meta`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getCarePlanList(patientId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/care-plan/list`);

        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function createCarePlan(patientId) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/care-plan/new`);

        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getCarePlanContent(patientId, carePlanId, stepName) {
    try {
        const response = await axios.get(
            `/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/care-plan/${carePlanId}/content/${stepName}`
        );

        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function postCarePlanContent(patientId, carePlanId, stepName, data) {
    try {
        const response = await axios.post(
            `/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/care-plan/${carePlanId}/content/${stepName}`,
            data
        );

        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function postFinalizeCarePlan(patientId, carePlanId) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/care-plan/${carePlanId}/finalize`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function postDuplicateCarePlan(patientId, carePlanId) {
    try {
        const response = await axios.post(
            `/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/care-plan/${carePlanId}/duplicate`
        );
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}
