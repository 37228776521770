// routing
import { LicenseInfo } from '@mui/x-license-pro';
import Routes from 'routes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

// ==============================|| APP ||============================== //

const App = () => (
    <ThemeCustomization>
        {/* RTL layout */}
        <RTLLayout>
            <NavigationScroll>
                <AuthProvider>
                    <>
                        <Routes />
                        <Snackbar />
                    </>
                </AuthProvider>
            </NavigationScroll>
        </RTLLayout>
    </ThemeCustomization>
);

export default App;
