import axios from 'utils/axios';
import { each } from 'lodash';

// eslint-disable-next-line no-unused-vars
export async function getVitalsDataMeta(patientId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/vitals/meta`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getVitalsData(patientId, type) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/vitals/data`, {
            params: {
                type
            }
        });

        each(response.data.vitalsData, (vitalsEntry) => {
            vitalsEntry.readingTime = vitalsEntry.readingTime ? new Date(vitalsEntry.readingTime).getTime() : '';
        });

        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getVitalsDevices(patientId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/vitals/devices`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function addVitalsDevice(patientId, deviceId, vitalsProviderName) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/vitals/devices/add`, {
            deviceId,
            vitalsProviderName
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}
