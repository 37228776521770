/**
 * axios setup to use mock service
 */

import axios from 'axios';

const axiosServices = axios.create();

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export function setOrgIdInterceptor(orgId) {
    // clear for if being called more than once
    axiosServices.interceptors.request.clear();

    // set interceptor to replace orgId
    const orgIdReplacementString = `/${orgId}/`;
    axiosServices.interceptors.request.use(async (config) => {
        if (config.url.includes('/{orgId}/')) {
            config.url = config.url.replace('/{orgId}/', orgIdReplacementString);
        }

        if (config.url.includes('/{clinicId}/') && window.location.pathname.startsWith('/clinic/')) {
            const clinicId = window.location.pathname.split('/')[2];
            config.url = config.url.replace('/{clinicId}/', `/${clinicId}/`);
        }

        return config;
    });
}

export default axiosServices;
