import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

import * as customProgrammaticTaskAPI from 'api/customProgrammaticTask';

const initialState = {
    list: {
        error: false,
        loaded: false,
        data: []
    },
    instance: {
        error: false,
        loaded: false,
        data: {}
    }
};

const slice = createSlice({
    name: 'customProgrammaticTask',
    initialState,
    reducers: {
        setCustomProgrammaticTaskListError(state) {
            state.list.error = true;
            state.list.loaded = true;
        },
        setCustomProgrammaticTaskListData(state, action) {
            state.list.error = false;
            state.list.loaded = true;
            state.list.data = action.payload;
        },
        resetListState(state) {
            state.list = initialState.list;
        },
        setCustomProgrammaticTaskInstanceData(state, action) {
            state.instance.error = false;
            state.instance.loaded = true;
            state.instance.data = action.payload;
        },
        setCustomProgrammaticTaskInstanceError(state, action) {
            state.instance.error = action.payload;
            state.instance.loaded = true;
        },
        resetInstance(state) {
            state.instance = initialState.instance;
        }
    }
});

export default slice.reducer;

export function resetListState() {
    dispatch(slice.actions.resetListState());
}

export function resetInstance() {
    dispatch(slice.actions.resetInstance());
}

export function getCustomProgrammaticTaskList() {
    return async () => {
        try {
            const response = await customProgrammaticTaskAPI.getCustomProgrammaticTaskList();
            dispatch(slice.actions.setCustomProgrammaticTaskListData(response.data.list));
        } catch (error) {
            dispatch(slice.actions.setCustomProgrammaticTaskListError(error));
        }
    };
}

export function getCustomProgrammaticTaskInstance(id) {
    return async () => {
        try {
            const response = await customProgrammaticTaskAPI.getCustomProgrammticTaskInstance(id);
            dispatch(slice.actions.setCustomProgrammaticTaskInstanceData(response.data.instance));
        } catch (error) {
            dispatch(slice.actions.setCustomProgrammaticTaskInstanceError(error));
        }
    };
}
