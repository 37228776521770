import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

import * as treatmentPlanAPI from 'api/treatmentPlan';

const initialState = {
    definitions: {
        error: false,
        loaded: false,
        // assessments indexed by treatmentPlanId
        data: {}
    }
};

const slice = createSlice({
    name: 'treatmentPlan',
    initialState,
    reducers: {
        setTreatmentPlanDefinitionError(state) {
            state.definitions.error = true;
            state.definitions.loaded = true;
        },
        setTreatmentPlanDefinition(state, action) {
            state.definitions.error = false;
            state.definitions.loaded = true;
            state.definitions.data = action.payload;
        },
        resetAssessmentDefinitions(state) {
            state.definitions = initialState.definitions;
        }
    }
});

export default slice.reducer;

export function resetAssessmentDefinitions() {
    dispatch(slice.actions.resetAssessmentDefinitions());
}

export function getAssessmentDefinitions() {
    return async () => {
        try {
            const response = await treatmentPlanAPI.getAssessmentDefinitions();
            dispatch(slice.actions.setTreatmentPlanDefinition(response.data.definitions));
        } catch (error) {
            dispatch(slice.actions.setTreatmentPlanDefinitionError(error));
        }
    };
}
