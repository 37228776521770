// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import * as userAPI from 'api/user';

const initialState = {
    list: {
        error: false,
        loaded: false,
        data: []
    },
    userDetail: {
        error: false,
        loaded: false,
        data: {
            id: 0,
            firstName: '',
            lastName: '',
            email: '',
            disabled: false,
            orgGrants: [],
            clinicGrants: {},
            providerClinicMapping: [],
            // null if not a provider account
            providerId: null,
            // if has the provider clinic matching for selected clinic
            assignedToClinic: true,
            primaryCarePhysician: false,
            specialist: false,
            title: '',
            npi: ''
        }
    }
};

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUserListError(state) {
            state.list.error = true;
            state.list.loaded = true;
        },
        setUserList(state, action) {
            state.list.error = false;
            state.list.loaded = true;
            state.list.data = action.payload;
        },
        setUserDetail(state, action) {
            state.userDetail.error = false;
            state.userDetail.loaded = true;
            state.userDetail.data = action.payload;
        },
        setUserDetailError(state, action) {
            state.userDetail.error = action.payload;
            state.userDetail.loaded = true;
        },
        resetState(state) {
            state.list = initialState.list;
            state.userDetail = initialState.userDetail;
        },
        resetUserDetailState(state) {
            state.userDetail = initialState.userDetail;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function resetState() {
    dispatch(slice.actions.resetState());
}

export function getUserList() {
    return async () => {
        try {
            const response = await userAPI.listUsers();
            dispatch(slice.actions.setUserList(response.data.users));
        } catch (error) {
            dispatch(slice.actions.setUserListError(error));
        }
    };
}

export function getUserDetail(userId) {
    return async () => {
        try {
            const response = await userAPI.getUserDetail(userId);
            dispatch(slice.actions.setUserDetail(response.data.user));
        } catch (error) {
            dispatch(slice.actions.setUserDetailError(error));
        }
    };
}

export function resetUserDetail() {
    dispatch(slice.actions.resetUserDetailState());
}
