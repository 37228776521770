// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import * as protocolsAPI from 'api/protocols';
import { loadEmbeddedTaskById } from 'store/slices/task';

const initialState = {
    // // holds the enabled protocol templates for creation of a new instance
    templateList: {
        error: false,
        loaded: false,
        data: []
    },
    // holds the list of all instances
    list: {
        error: false,
        loaded: false,
        data: []
    },
    instance: {
        error: false,
        loaded: false,
        status: '',
        linkedTaskId: null,
        template: {},
        content: {},
        autofill: {}
    },
    autoSave: {
        error: false,
        lastSavedTS: null
    }
};

const slice = createSlice({
    name: 'protocolsInstance',
    initialState,
    reducers: {
        setProtocolsInstanceListError(state) {
            state.list.error = true;
            state.list.loaded = true;
        },
        setProtocolsInstanceListData(state, action) {
            state.list.error = false;
            state.list.loaded = true;
            state.list.data = action.payload;
        },
        resetListState(state) {
            state.list = initialState.list;
        },

        setProtocolsTemplateListError(state) {
            state.templateList.error = true;
            state.templateList.loaded = true;
        },
        setProtocolsTemplateListData(state, action) {
            state.templateList.error = false;
            state.templateList.loaded = true;
            state.templateList.data = action.payload;
        },
        resetTemplateListState(state) {
            state.templateList = initialState.templateList;
        },

        setProtocolsInstanceError(state) {
            state.instance.error = true;
            state.instance.loaded = true;
        },
        setProtocolsInstanceData(state, action) {
            state.instance.error = false;
            state.instance.loaded = true;
            state.instance.template = action.payload.template;
            state.instance.content = action.payload.content;
            state.instance.status = action.payload.status;
            state.instance.autofill = action.payload.autofill;
        },
        resetInstanceState(state) {
            state.instance = initialState.instance;
        },

        updateProtocolsAutoSaveSuccess(state, action) {
            state.autoSave.error = false;
            state.autoSave.lastSavedTS = action.payload;
        },
        updateProtocolsAutoSaveError(state) {
            state.autoSave.error = true;
        },
        resetAutoSaveState(state) {
            state.autoSave = initialState.autoSave;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function resetListState() {
    dispatch(slice.actions.resetListState());
}

export function resetTemplateListState() {
    dispatch(slice.actions.resetListState());
}

export function resetInstanceState() {
    dispatch(slice.actions.resetInstanceState());
}

export function resetAutoSaveState() {
    dispatch(slice.actions.resetAutoSaveState());
}

export function getProtocolsEnabledTemplatesList() {
    return async () => {
        try {
            const response = await protocolsAPI.getEnabledProtocolsTemplateList();
            dispatch(slice.actions.setProtocolsTemplateListData(response.data.list));
        } catch (error) {
            dispatch(slice.actions.setProtocolsTemplateListError(error));
        }
    };
}

export function getProtocolsInstanceList(patientId) {
    return async () => {
        try {
            const response = await protocolsAPI.getProtocolsInstanceList(patientId);
            dispatch(slice.actions.setProtocolsInstanceListData(response.data.list));
        } catch (error) {
            dispatch(slice.actions.setProtocolsInstanceListError(error));
        }
    };
}

// also fetches embeddded task data
export function getProtocolsInstanceData(patientId, protocolInstanceId) {
    return async () => {
        try {
            const response = await protocolsAPI.getProtocolsInstanceData(patientId, protocolInstanceId);
            if (response.data.instance.linkedTaskId) {
                // returns an async function
                await loadEmbeddedTaskById(response.data.instance.linkedTaskId)();
            }
            dispatch(slice.actions.setProtocolsInstanceData(response.data.instance));
        } catch (error) {
            dispatch(slice.actions.setProtocolsInstanceError(error));
        }
    };
}

export function updatePrococolsAutoSaveSuccess(ts) {
    dispatch(slice.actions.updateProtocolsAutoSaveSuccess(ts));
}

export function updateProtocolsAutoSaveError() {
    dispatch(slice.actions.updateProtocolsAutoSaveError());
}
