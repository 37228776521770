export const VITALS_DATA_TYPES = {
    BLOOD_GLUCOSE: 'BLOOD_GLUCOSE',
    BLOOD_PRESSURE: 'BLOOD_PRESSURE',
    PULSE_OXIMETER: 'PULSE_OXIMETER',
    WEIGHT: 'WEIGHT'
};

function dateFormatter(params) {
    if (params.value) {
        return new Date(params.value).toLocaleString();
    }
    return '';
}

export const VITALS_DATA_COLUMN_DEFINITIONS = {
    BLOOD_GLUCOSE: [
        {
            field: 'readingTime',
            headerName: 'Reading Time',
            valueFormatter: dateFormatter,
            flex: 1.5
        },
        {
            field: 'bloodGlucoseMGDL',
            headerName: 'Blood Glucose MGDL',
            flex: 1
        },
        {
            field: 'bloodGlucoseMMOL',
            headerName: 'Blood Glucose MMOL',
            flex: 1
        },
        {
            field: 'eventInfo',
            headerName: 'Event Info',
            valueFormatter: (params) => {
                if (params.value === 'BEFORE_MEAL') {
                    return 'Before Meal';
                }
                if (params.value === 'AFTER_MEAL') {
                    return 'After Meal';
                }
                if (params.value === 'CONTROL_SOLUTION') {
                    return 'Control Solution';
                }
                if (params.value === 'MOCK_TEST') {
                    return 'Mock Test';
                }
                return params.value;
            },
            flex: 1
        },
        {
            field: 'battery',
            headerName: 'Battery %',
            flex: 1
        }
    ],
    BLOOD_PRESSURE: [
        {
            field: 'readingTime',
            headerName: 'Reading Time',
            valueFormatter: dateFormatter,
            flex: 1.5
        },
        {
            field: 'systolicMMHG',
            headerName: 'Systolic MMHG',
            flex: 1
        },
        {
            field: 'diastolicMMHG',
            headerName: 'Diastolic MMHG',
            flex: 1
        },
        {
            field: 'pulseBPM',
            headerName: 'Pulse BPM',
            flex: 1
        },
        {
            field: 'irregular',
            headerName: 'Irregular Pulse',
            valueFormatter: (params) => (params.value ? 'Yes' : 'No'),
            flex: 1
        }
    ],
    PULSE_OXIMETER: [
        {
            field: 'readingTime',
            headerName: 'Reading Time',
            valueFormatter: dateFormatter,

            flex: 1.5
        },
        {
            field: 'spo2',
            headerName: 'SPO2',
            flex: 1
        },
        {
            field: 'pulseBPM',
            headerName: 'Pulse BPM',
            flex: 1
        }
    ],
    WEIGHT: [
        {
            field: 'readingTime',
            valueFormatter: dateFormatter,
            headerName: 'Reading Time',
            flex: 1.5
        },
        {
            field: 'tareKG',
            headerName: 'Tare(kg)',
            flex: 0.5
        },
        {
            field: 'weightKG',
            headerName: 'Weight(kg)',
            flex: 1
        },
        {
            field: 'tareLBS',
            headerName: 'Tare(lbs)',
            flex: 0.5
        },
        {
            field: 'weightLBS',
            headerName: 'Weight(lbs)',
            flex: 1
        }
    ]
};

export function getVitalsPrettyName(vitalsDataType) {
    switch (vitalsDataType) {
        case 'BLOOD_GLUCOSE':
            return 'Blood Glucose';
        case 'BLOOD_PRESSURE':
            return 'Blood Pressure';
        case 'PULSE_OXIMETER':
            return 'Pulse Oximeter';
        case 'WEIGHT':
            return 'Weight';
        default:
            return '(none)';
    }
}
