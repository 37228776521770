// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';

import { getVitalsAlerts, getVitalsAlertsStats } from 'api/vitalsAlerts';

// ----------------------------------------------------------------------

const initialState = {
    alerts: {
        error: false,
        loaded: false,
        data: []
    },
    count: {
        error: false,
        loaded: false,
        clinic: 0
    }
};

const slice = createSlice({
    name: 'vitalsAlerts',
    initialState,
    reducers: {
        setAlerts(state, action) {
            state.alerts.loaded = true;
            state.alerts.error = false;
            state.alerts.data = action.payload;
        },
        setAlertsError(state, action) {
            state.alerts.loaded = true;
            state.alerts.error = action.payload;
        },
        setAlertsStats(state, action) {
            state.count.loaded = true;
            state.count.error = false;
            state.count.clinic = action.payload;
        },
        setAlertsStatsError(state, action) {
            state.count.loaded = true;
            state.count.error = action.payload;
        },
        resetState(state) {
            state.alerts = initialState.alerts;
            state.count = initialState.count;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function resetState() {
    return dispatch(slice.actions.resetState());
}

export function getAlerts() {
    return async () => {
        const response = await getVitalsAlerts();
        if (response.status === 200) {
            dispatch(slice.actions.setAlerts(response.data.alerts));
        } else {
            dispatch(slice.actions.setAlertsError(response.message));
        }
    };
}

export function getAlertsStats(clinicId) {
    return async () => {
        const response = await getVitalsAlertsStats(clinicId);
        if (response.status === 200) {
            dispatch(slice.actions.setAlertsStats(response.data.count));
        } else {
            dispatch(slice.actions.setAlertsStatsError(response.message));
        }
    };
}
