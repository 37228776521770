import axios from 'utils/axios';

export async function createCustomProgrammaticTask() {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/custom-programmatic-task/create`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function updateCustomProgrammaticTask(customProgTaskDefId, name, templateText, taskDueDays, protocolTemplateId, conditions) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/custom-programmatic-task/${customProgTaskDefId}`, {
            name,
            templateText,
            taskDueDays,
            protocolTemplateId,
            conditions: {
                ...conditions,
                LATEST_FINALIZED_CARE_PLAN_ICD10_CODE: conditions.LATEST_FINALIZED_CARE_PLAN_ICD10_CODE.map((row) => row.data.id)
            }
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getCustomProgrammaticTaskList() {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/custom-programmatic-task/list`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}

export async function getCustomProgrammticTaskInstance(customProgTaskDefId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/custom-programmatic-task/${customProgTaskDefId}`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}
