// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import * as protocolsAPI from 'api/protocols';

const initialState = {
    list: {
        error: false,
        loaded: false,
        data: []
    },
    // loaded instance from server
    template: {
        error: false,
        loaded: false,
        data: {}
    },
    // surveyjs passing data to normal form
    surveyJSSerializedTemplate: ''
};

const slice = createSlice({
    name: 'protocolsTemplate',
    initialState,
    reducers: {
        setProtocolsTemplateListError(state) {
            state.list.error = true;
            state.list.loaded = true;
        },
        setProtocolsTemplateListData(state, action) {
            state.list.error = false;
            state.list.loaded = true;
            state.list.data = action.payload;
        },
        resetListState(state) {
            state.list = initialState.list;
        },
        setProtocolsTemplateData(state, action) {
            state.template.error = false;
            state.template.loaded = true;
            state.template.data = action.payload;
        },
        setProtocolsTemplateError(state, action) {
            state.template.error = action.payload;
            state.template.loaded = true;
        },
        resetTemplateState(state) {
            state.template = initialState.template;
        },
        resetSurveyJSSerializedTemplate(state) {
            state.surveyJSSerializedTemplate = initialState.surveyJSSerializedTemplate;
        },
        setSurveyJSSerializedTemplate(state, action) {
            state.surveyJSSerializedTemplate = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function resetListState() {
    dispatch(slice.actions.resetListState());
}

export function resetTemplateState() {
    dispatch(slice.actions.resetTemplateState());
}

export function resetSurveyJSSerializedTemplate() {
    dispatch(slice.actions.resetSurveyJSSerializedTemplate());
}

export function getProtocolsTemplateList() {
    return async () => {
        try {
            const response = await protocolsAPI.getProtocolsTemplateList();
            dispatch(slice.actions.setProtocolsTemplateListData(response.data.list));
        } catch (error) {
            dispatch(slice.actions.setProtocolsTemplateListError(error));
        }
    };
}

export function getProtocolsTemplate(templateId) {
    return async () => {
        try {
            const response = await protocolsAPI.getProtocolsTemplate(templateId);
            dispatch(slice.actions.setProtocolsTemplateData(response.data.template));
        } catch (error) {
            dispatch(slice.actions.setProtocolsTemplateError(error));
        }
    };
}

export function setSurveyJSSerializedTemplate(serializedTemplate) {
    return async () => {
        dispatch(slice.actions.setSurveyJSSerializedTemplate(serializedTemplate));
    };
}
