import { each, cloneDeep } from 'lodash';

import { formatDateUTCWithoutTime, formatAsLocalizedDateIgnoringTZ } from 'utils/date';

export function parseStep3DatesAsUTCWithoutTime(content) {
    if (content?.medicationAdherenceReviewDate) {
        content.medicationAdherenceReviewDate = formatAsLocalizedDateIgnoringTZ(content.medicationAdherenceReviewDate);
    }
}

export function formatStep3DatesAsUTCWithoutTime(content) {
    const clonedContent = cloneDeep(content);

    if (content?.medicationAdherenceReviewDate) {
        content.medicationAdherenceReviewDate = formatDateUTCWithoutTime(content.medicationAdherenceReviewDate);
    }

    return clonedContent;
}

export function parseStep4DatesAsUTCWithoutTime(content) {
    each(content.vitals, (row) => {
        each(['date1', 'date2', 'date3'], (dateKey) => {
            if (row?.[dateKey]) {
                row[dateKey] = formatAsLocalizedDateIgnoringTZ(row[dateKey]);
            }
        });
    });
}

export function formatStep4DatesAsUTCWithoutTime(content) {
    const clonedContent = cloneDeep(content);

    each(clonedContent.vitals, (row) => {
        each(['date1', 'date2', 'date3'], (dateKey) => {
            if (row?.[dateKey]) {
                row[dateKey] = formatDateUTCWithoutTime(row[dateKey]);
            }
        });
    });

    return clonedContent;
}

export function parseStep5DatesAsUTCWithoutTime(content) {
    each(content.labs, (row) => {
        each(['date1', 'date2', 'date3'], (dateKey) => {
            if (row?.[dateKey]) {
                row[dateKey] = formatAsLocalizedDateIgnoringTZ(row[dateKey]);
            }
        });
    });
}

export function formatStep5DatesAsUTCWithoutTime(content) {
    const clonedContent = cloneDeep(content);

    each(clonedContent.labs, (row) => {
        each(['date1', 'date2', 'date3'], (dateKey) => {
            if (row?.[dateKey]) {
                row[dateKey] = formatDateUTCWithoutTime(row[dateKey]);
            }
        });
    });

    return clonedContent;
}

export function parseStep6DatesAsUTCWithoutTime(content) {
    each(content.imaging, (row) => {
        each(['date1', 'date2', 'date3'], (dateKey) => {
            if (row?.[dateKey]) {
                row[dateKey] = formatAsLocalizedDateIgnoringTZ(row[dateKey]);
            }
        });
    });
}

export function formatStep6DatesAsUTCWithoutTime(content) {
    const clonedContent = cloneDeep(content);

    each(clonedContent.imaging, (row) => {
        each(['date1', 'date2', 'date3'], (dateKey) => {
            if (row?.[dateKey]) {
                row[dateKey] = formatDateUTCWithoutTime(row[dateKey]);
            }
        });
    });

    return clonedContent;
}

export function parseStep7DatesAsUTCWithoutTime(content) {
    each(content.screening, (row) => {
        if (row?.lastDateCompleted) {
            row.lastDateCompleted = formatAsLocalizedDateIgnoringTZ(row.lastDateCompleted);
        }
    });
}

export function formatStep7DatesAsUTCWithoutTime(content) {
    const clonedContent = cloneDeep(content);

    each(clonedContent.screening, (row) => {
        if (row?.lastDateCompleted) {
            row.lastDateCompleted = formatDateUTCWithoutTime(row.lastDateCompleted);
        }
    });

    return clonedContent;
}

export function parseStep10DatesAsUTCWithoutTime(content) {
    each(content.referrals, (row) => {
        if (row?.date) {
            row.date = formatAsLocalizedDateIgnoringTZ(row.date);
        }
    });
}

export function formatStep10DatesAsUTCWithoutTime(content) {
    const clonedContent = cloneDeep(content);

    each(clonedContent.referrals, (row) => {
        if (row?.date) {
            row.date = formatDateUTCWithoutTime(row.date);
        }
    });

    return clonedContent;
}
