import axios from 'utils/axios';

export async function getAssessmentDefinitions() {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/treatment-plan/definitions`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}

export async function getActiveAssessments(patientId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/assessments/active`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}
