import { defaults } from 'lodash';
import axios from 'utils/axios';
import moment from 'moment';

const ENROLLMENT_DATE_FIELDS = ['enrollmentDate', 'cancellationDate', 'equipmentSetupAndEducation', 'patientAgreement', 'informationSheet'];

function formatAsLocalizedDateIgnoringTZ(dateString) {
    const parsedDate = new Date(dateString);
    const dateComponent = `${parsedDate.getUTCFullYear()}-${parsedDate.getUTCMonth() + 1}-${parsedDate.getUTCDate()}`;
    return moment(dateComponent, 'YYYY-MM-DD').toString();
}

export async function getEnrollment(patientId, type) {
    const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/enrollment/${type}`);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ENROLLMENT_DATE_FIELDS.length; i++) {
        const dateProp = ENROLLMENT_DATE_FIELDS[i];
        if (response.data.enrollment[dateProp]) {
            response.data.enrollment[dateProp] = formatAsLocalizedDateIgnoringTZ(response.data.enrollment[dateProp]);
        }
    }

    return {
        data: {
            success: true,
            enrollment: defaults(response.data.enrollment, {
                type,
                enrollmentDate: null,
                cancellationDate: null,
                verbalAgreement: false,
                enrolledSameDayOfficeVisit: false,
                primaryClinician: null,
                specialist: null
            })
        }
    };
}

function formatDateUTCWithoutTime(dateString) {
    const parsedDate = new Date(dateString);
    const dateComponent = `${parsedDate.getFullYear()}-${parsedDate.getMonth() + 1}-${parsedDate.getDate()}`;
    return moment.utc(dateComponent, 'YYYY-MM-DD').toISOString();
}

function formatDateFields(data) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ENROLLMENT_DATE_FIELDS.length; i++) {
        const dateProp = ENROLLMENT_DATE_FIELDS[i];
        if (data[dateProp]) {
            data[dateProp] = formatDateUTCWithoutTime(data[dateProp]);
        }
    }
}

export async function postEnrollment(patientId, type, data) {
    formatDateFields(data);
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/enrollment/${type}`, data);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getCurrentEnrollments(patientId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/enrollment/status`);

        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getEnrollmentStats(clinicId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/${clinicId}/enrollment/stats`);

        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getEnrollmentDocumentStatus(patientId, type) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/enrollment/${type}/documents`);

        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}

// eslint-disable-next-line
// export async function getCurrentEnrollments(patientId) {
//     return {
//         data: {
//             success: true,
//             currentEnrollments: {
//                 ccm: true,
//                 bhi: true,
//                 rpm: false,
//                 pcm: false
//             }
//         }
//     };
// }
