// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import * as providerAPI from 'api/provider';

const initialState = {
    providerTitles: {
        error: false,
        data: []
    },
    primaryClinicianList: {
        error: false,
        loaded: false,
        data: []
    },
    specialistList: {
        error: false,
        data: []
    },
    clinicMembersList: {
        error: false,
        loaded: false,
        data: []
    }
};

const slice = createSlice({
    name: 'provider',
    initialState,
    reducers: {
        setProviderTitlesError(state) {
            state.providerTitles.error = true;
        },
        setProviderTitles(state, action) {
            state.providerTitles.data = action.payload;
        },
        setPrimaryClinicianList(state, action) {
            state.primaryClinicianList.error = false;
            state.primaryClinicianList.loaded = true;
            state.primaryClinicianList.data = action.payload;
        },
        setPrimaryClinicianListError(state) {
            state.primaryClinicianList.error = true;
            state.primaryClinicianList.loaded = false;
        },
        setSpecialistList(state, action) {
            state.specialistList.error = false;
            state.specialistList.data = action.payload;
        },
        setSpecialistListError(state) {
            state.specialistList.error = true;
        },
        setClinicMembersList(state, action) {
            state.clinicMembersList.error = false;
            state.clinicMembersList.loaded = true;
            state.clinicMembersList.data = action.payload;
        },
        setClinicMembersError(state, action) {
            state.clinicMembersList.error = action.payload;
            state.clinicMembersList.loaded = true;
        },
        resetClinicMembersList(state) {
            state.clinicMembersList.error = false;
            state.clinicMembersList.loaded = false;
            state.clinicMembersList.data = [];
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProviderTitles() {
    return async () => {
        try {
            const response = await providerAPI.getProviderTitles();
            dispatch(slice.actions.setProviderTitles(response.data.providerTitles));
        } catch (error) {
            dispatch(slice.actions.setProviderTitlesError(error));
        }
    };
}

export function getPrimaryClinicianList() {
    return async () => {
        try {
            const response = await providerAPI.getPrimaryClinicianList();
            dispatch(slice.actions.setPrimaryClinicianList(response.data.primaryClinicianList));
        } catch (error) {
            dispatch(slice.actions.setPrimaryClinicianListError());
        }
    };
}

export function getSpecialistList() {
    return async () => {
        try {
            const response = await providerAPI.getSpecialistList();
            dispatch(slice.actions.setSpecialistList(response.data.specialistList));
        } catch (error) {
            dispatch(slice.actions.setSpecialistListError());
        }
    };
}

export function getClinicMembersList(clinicId) {
    return async () => {
        try {
            const response = await providerAPI.getClinicMembersList(clinicId);
            dispatch(slice.actions.setClinicMembersList(response.data.providers));
        } catch (error) {
            dispatch(slice.actions.setClinicMembersError(error));
        }
    };
}

export function resetClinicMembersList() {
    dispatch(slice.actions.resetClinicMembersList());
}
