export function humanFormattedAsMinSec(careSeconds) {
    const minutes = Math.floor(careSeconds / 60);
    const seconds = careSeconds - minutes * 60;

    return `${minutes}m ${seconds}s`;
}

export function humanFormattedDecimalHours(careSeconds) {
    const hours = Math.round((careSeconds / 3600) * 100) / 100;

    return `${hours} hours`;
}
