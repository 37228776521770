// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import * as clinicSettingsAPI from 'api/clinicSettings';
import * as alertSnackbar from 'utils/snackbar-wrapper';

const initialState = {
    programmaticTask: {
        error: false,
        loaded: false,
        data: []
    },
    programmaticTaskPreviewStatus: {
        error: false,
        loaded: false,
        data: {
            completed: false
        }
    },
    programmaticTaskPreviewResults: {
        error: false,
        loaded: false,
        data: []
    },
    userSuppliedInfo: {
        error: false,
        loaded: false,
        data: {
            name: '',
            address: ''
        }
    }
};

const slice = createSlice({
    name: 'clinicSettings',
    initialState,
    reducers: {
        setProgrammaticTaskError(state) {
            state.programmaticTask.error = true;
            state.programmaticTask.loaded = true;
        },
        setProgrammaticTask(state, action) {
            state.programmaticTask.loaded = true;
            state.programmaticTask.data = action.payload;
        },
        setProgrammaticTaskPreviewStatusError(state) {
            state.programmaticTaskPreviewStatus.error = true;
            state.programmaticTaskPreviewStatus.loaded = true;
        },
        setProgrammaticTaskPreviewStatus(state, action) {
            state.programmaticTaskPreviewStatus.loaded = true;
            state.programmaticTaskPreviewStatus.data = action.payload;
        },
        setProgrammaticTaskPreviewResultsError(state) {
            state.programmaticTaskPreviewResults.error = true;
            state.programmaticTaskPreviewResults.loaded = true;
        },
        setProgrammaticTaskPreviewResults(state, action) {
            state.programmaticTaskPreviewResults.loaded = true;
            state.programmaticTaskPreviewResults.data = action.payload;
        },
        setUserSuppliedInfoError(state) {
            state.userSuppliedInfo.error = true;
            state.userSuppliedInfo.loaded = true;
        },
        setUserSuppliedInfo(state, action) {
            state.userSuppliedInfo.loaded = true;
            state.userSuppliedInfo.data = action.payload;
        },
        resetUserSuppliedInfoState(state) {
            state.userSuppliedInfo = initialState.userSuppliedInfo;
        },
        resetProgrammaticTaskListState(state) {
            state.programmaticTask = initialState.programmaticTask;
        },
        resetProgrammaticTaskPreviewState(state) {
            state.programmaticTaskPreviewStatus = initialState.programmaticTaskPreviewStatus;
            state.programmaticTaskPreviewResults = initialState.programmaticTaskPreviewResults;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function resetClinicUserSuppliedInfoState() {
    dispatch(slice.actions.resetUserSuppliedInfoState());
}

export function resetProgrammaticTaskListState() {
    dispatch(slice.actions.resetProgrammaticTaskListState());
}

export function resetProgrammaticTaskPreviewState() {
    dispatch(slice.actions.resetProgrammaticTaskPreviewState());
}

export function getProgrammaticTaskSettings(clinicId) {
    return async () => {
        const response = await clinicSettingsAPI.getProgrammaticTaskSettings(clinicId);
        if (response?.data?.programmaticTask) {
            dispatch(slice.actions.setProgrammaticTask(response.data.programmaticTask));
        } else {
            dispatch(alertSnackbar.error(`Failed to fetch programmatic task settings: ${response.message}`));
            dispatch(slice.actions.setProgrammaticTaskError(response));
        }
    };
}

export function getUserSuppliedInfo(clinicId) {
    return async () => {
        const response = await clinicSettingsAPI.getClinicUserSubmittedInfo(clinicId);
        if (response?.data?.userSuppliedInfo) {
            dispatch(slice.actions.setUserSuppliedInfo(response.data.userSuppliedInfo));
        } else {
            dispatch(alertSnackbar.error(`Failed to fetch clinic info: ${response.message}`));
            dispatch(slice.actions.setUserSuppliedInfoError(response));
        }
    };
}

export function getProgrammaticTaskPreviewStatus(previewRunId) {
    return async () => {
        try {
            const response = await clinicSettingsAPI.getProgrammaticTaskPreviewStatus(previewRunId);
            dispatch(slice.actions.setProgrammaticTaskPreviewStatus(response.data.status));
        } catch (error) {
            if (error && error.statusCode && error.message && error.statusCode === 403) {
                dispatch(alertSnackbar.warning(error.message));
            } else {
                dispatch(alertSnackbar.error(`Failed to fetch preview status`));
            }
            dispatch(slice.actions.setProgrammaticTaskPreviewStatusError(error));
        }
    };
}

export function getProgrammaticTaskPreviewResults(previewRunId) {
    return async () => {
        try {
            const response = await clinicSettingsAPI.getProgrammaticTaskPreviewResults(previewRunId);
            dispatch(slice.actions.setProgrammaticTaskPreviewResults(response.data.previewResults));
        } catch (error) {
            dispatch(alertSnackbar.error(`Failed to fetch preview results`));
            dispatch(slice.actions.setProgrammaticTaskPreviewResultsError(error));
        }
    };
}
