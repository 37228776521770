// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import calendarReducer from './slices/calendar';
import menuReducer from './slices/menu';
import patientListReducer from './slices/patientList';
import providerListReducer from './slices/providersList';
import patientReducer from './slices/patient';
import providerReducer from './slices/provider';
import enrollmentReducer from './slices/enrollment';
import careSessionListReducer from './slices/careSessionList';
import carePlanReducer from './slices/carePlan';
import documentsReducer from './slices/documents';
import accountReducer from './slices/account';
import vitalsDataReducer from './slices/vitalsData';
import vitalsAlertsReducer from './slices/vitalsAlerts';
import taskReducer from './slices/task';
import usersReducer from './slices/users';
import clinicSettingsReducer from './slices/clinicSettings';
import protocolsTemplateReducer from './slices/protocolsTemplate';
import protocolsInstanceReducer from './slices/protocolsInstance';
import customProgrammaticTaskReducer from './slices/customProgrammaticTask';
import treatmentPlanReducer from './slices/treatmentPlan';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    calendar: calendarReducer,
    menu: menuReducer,
    patientList: patientListReducer,
    providerList: providerListReducer,
    patient: patientReducer,
    provider: providerReducer,
    enrollment: enrollmentReducer,
    careSessionList: careSessionListReducer,
    carePlan: carePlanReducer,
    documents: documentsReducer,
    account: accountReducer,
    vitalsData: vitalsDataReducer,
    vitalsAlerts: vitalsAlertsReducer,
    task: taskReducer,
    users: usersReducer,
    clinicSettings: clinicSettingsReducer,
    protocolsTemplate: protocolsTemplateReducer,
    protocolsInstance: protocolsInstanceReducer,
    customProgrammaticTask: customProgrammaticTaskReducer,
    treatmentPlan: treatmentPlanReducer
});

export default reducer;
