// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';

import { getCarePlanList, getCarePlanMeta, getCarePlanContent } from 'api/carePlan';
import {
    parseStep3DatesAsUTCWithoutTime,
    parseStep4DatesAsUTCWithoutTime,
    parseStep5DatesAsUTCWithoutTime,
    parseStep6DatesAsUTCWithoutTime,
    parseStep7DatesAsUTCWithoutTime,
    parseStep10DatesAsUTCWithoutTime
} from 'utils/carePlanDateWithoutTimeHandler';

/*
There are 11 steps stored on the server and a "12th step" displayed on client only 
to finalize the care plan which does not have data stored with it
*/

// ----------------------------------------------------------------------

const initialState = {
    list: {
        error: false,
        loaded: false,
        carePlans: []
    },
    meta: {
        error: false,
        loaded: false,
        highestCompletedStep: '',
        highestSubmittedStep: '',
        // store a timestamp here for editing existing and refetching meta fast forwards you to current
        timestamp: new Date().getTime()
    },
    step1: {
        error: false,
        loaded: false,
        content: {}
    },
    step2: {
        error: false,
        loaded: false,
        content: {}
    },
    step3: {
        error: false,
        loaded: false,
        content: {}
    },
    step4: {
        error: false,
        loaded: false,
        content: {}
    },
    step5: {
        error: false,
        loaded: false,
        content: {}
    },
    step6: {
        error: false,
        loaded: false,
        content: {}
    },
    step7: {
        error: false,
        loaded: false,
        content: {}
    },
    step8: {
        error: false,
        loaded: false,
        content: {},
        diagnoses: []
    },
    step9: {
        error: false,
        loaded: false,
        content: {},
        diagnoses: []
    },
    step10: {
        error: false,
        loaded: false,
        content: {}
    },
    step11: {
        error: false,
        loaded: false,
        content: {}
    }
};

const slice = createSlice({
    name: 'carePlan',
    initialState,
    reducers: {
        // reset everything tied to a care plan (everything but list)
        resetIndividualCarePlanState(state) {
            state.meta = initialState.meta;
            state.step1 = initialState.step1;
            state.step2 = initialState.step2;
            state.step3 = initialState.step3;
            state.step4 = initialState.step4;
            state.step5 = initialState.step5;
            state.step6 = initialState.step6;
            state.step7 = initialState.step7;
            state.step8 = initialState.step8;
            state.step9 = initialState.step9;
            state.step10 = initialState.step10;
            state.step11 = initialState.step11;
        },

        resetStep1(state) {
            state.step1 = initialState.step1;
        },

        resetStep2(state) {
            state.step2 = initialState.step2;
        },

        resetStep3(state) {
            state.step3 = initialState.step3;
        },

        resetStep4(state) {
            state.step4 = initialState.step4;
        },

        resetStep5(state) {
            state.step5 = initialState.step5;
        },

        resetStep6(state) {
            state.step6 = initialState.step6;
        },

        resetStep7(state) {
            state.step7 = initialState.step7;
        },

        resetStep8(state) {
            // additionally reset 2 which is fetched during 8
            state.step2 = initialState.step2;
            state.step8 = initialState.step8;
        },

        resetStep9(state) {
            // additionally reset 2 which is fetched during 9
            state.step2 = initialState.step2;
            state.step9 = initialState.step9;
        },

        resetStep10(state) {
            state.step10 = initialState.step10;
        },

        resetStep11(state) {
            state.step11 = initialState.step11;
        },

        setCarePlanMetaError(state, action) {
            state.meta.error = action.payload;
            state.meta.loaded = true;
            state.meta.timestamp = new Date().getTime();
        },
        setCarePlanMetaData(state, action) {
            state.meta.error = false;
            state.meta.highestCompletedStep = action.payload.highestCompletedStep;
            state.meta.highestSubmittedStep = action.payload.highestSubmittedStep;
            state.meta.loaded = true;
            state.meta.timestamp = new Date().getTime();
        },

        setCarePlanListError(state, action) {
            state.list.error = action.payload;
            state.list.loaded = true;
        },

        setCarePlanListData(state, action) {
            state.list.error = false;
            state.list.carePlans = action.payload;
            state.list.loaded = true;
        },

        setCarePlanStep1Error(state, action) {
            state.step1.error = action.payload;
            state.step1.loaded = true;
        },
        setCarePlanStep1Data(state, action) {
            state.step1.error = false;
            state.step1.content = action.payload;
            state.step1.loaded = true;
        },

        setCarePlanStep2Error(state, action) {
            state.step2.error = action.payload;
            state.step2.loaded = true;
        },
        setCarePlanStep2Data(state, action) {
            state.step2.error = false;
            state.step2.content = action.payload;
            state.step2.loaded = true;
        },

        setCarePlanStep3Error(state, action) {
            state.step3.error = action.payload;
            state.step3.loaded = true;
        },
        setCarePlanStep3Data(state, action) {
            state.step3.error = false;
            state.step3.content = action.payload;
            state.step3.loaded = true;
        },

        setCarePlanStep4Error(state, action) {
            state.step4.error = action.payload;
            state.step4.loaded = true;
        },
        setCarePlanStep4Data(state, action) {
            state.step4.error = false;
            state.step4.content = action.payload;
            state.step4.loaded = true;
        },

        setCarePlanStep5Error(state, action) {
            state.step5.error = action.payload;
            state.step5.loaded = true;
        },
        setCarePlanStep5Data(state, action) {
            state.step5.error = false;
            state.step5.content = action.payload;
            state.step5.loaded = true;
        },

        setCarePlanStep6Error(state, action) {
            state.step6.error = action.payload;
            state.step6.loaded = true;
        },
        setCarePlanStep6Data(state, action) {
            state.step6.error = false;
            state.step6.content = action.payload;
            state.step6.loaded = true;
        },

        setCarePlanStep7Error(state, action) {
            state.step7.error = action.payload;
            state.step7.loaded = true;
        },
        setCarePlanStep7Data(state, action) {
            state.step7.error = false;
            state.step7.content = action.payload;
            state.step7.loaded = true;
        },

        setCarePlanStep8Error(state, action) {
            state.step8.error = action.payload;
            state.step8.loaded = true;
        },
        // step 8 passes in both content and diagnoses
        setCarePlanStep8Data(state, action) {
            state.step8.error = false;
            state.step8.content = action.payload.content;
            state.step8.diagnoses = action.payload.diagnoses;
            state.step8.loaded = true;
        },

        setCarePlanStep9Error(state, action) {
            state.step9.error = action.payload;
            state.step9.loaded = true;
        },
        // step 9 passes in both content and diagnoses
        setCarePlanStep9Data(state, action) {
            state.step9.error = false;
            state.step9.content = action.payload.content;
            state.step9.diagnoses = action.payload.diagnoses;
            state.step9.loaded = true;
        },

        setCarePlanStep10Error(state, action) {
            state.step10.error = action.payload;
            state.step10.loaded = true;
        },
        setCarePlanStep10Data(state, action) {
            state.step10.error = false;
            state.step10.content = action.payload;
            state.step10.loaded = true;
        },

        setCarePlanStep11Error(state, action) {
            state.step11.error = action.payload;
            state.step11.loaded = true;
        },
        setCarePlanStep11Data(state, action) {
            state.step11.error = false;
            state.step11.content = action.payload;
            state.step11.loaded = true;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getMeta(patientId, carePlanId) {
    return async () => {
        const response = await getCarePlanMeta(patientId, carePlanId);
        if (response.status === 200) {
            dispatch(slice.actions.setCarePlanMetaData(response.data));
        } else {
            dispatch(slice.actions.setCarePlanMetaError(response.message));
        }
    };
}

export function getList(patientId) {
    return async () => {
        const response = await getCarePlanList(patientId);
        if (response.status === 200) {
            dispatch(slice.actions.setCarePlanListData(response.data.carePlanList));
        } else {
            dispatch(slice.actions.setCarePlanListError(response.message));
        }
    };
}

export function getStep1Content(patientId, carePlanId) {
    return async () => {
        const response = await getCarePlanContent(patientId, carePlanId, 'step1');
        if (response.status === 200) {
            dispatch(slice.actions.setCarePlanStep1Data(response.data.content));
        } else {
            dispatch(slice.actions.setCarePlanStep1Error(response.message));
        }
    };
}

export function getStep2Content(patientId, carePlanId) {
    return async () => {
        const response = await getCarePlanContent(patientId, carePlanId, 'step2');
        if (response.status === 200) {
            dispatch(slice.actions.setCarePlanStep2Data(response.data.content));
        } else {
            dispatch(slice.actions.setCarePlanStep2Error(response.message));
        }
    };
}

export function getStep3Content(patientId, carePlanId) {
    return async () => {
        const response = await getCarePlanContent(patientId, carePlanId, 'step3');
        if (response.status === 200) {
            parseStep3DatesAsUTCWithoutTime(response.data.content);
            dispatch(slice.actions.setCarePlanStep3Data(response.data.content));
        } else {
            dispatch(slice.actions.setCarePlanStep3Error(response.message));
        }
    };
}

export function getStep4Content(patientId, carePlanId) {
    return async () => {
        const response = await getCarePlanContent(patientId, carePlanId, 'step4');
        if (response.status === 200) {
            parseStep4DatesAsUTCWithoutTime(response.data.content);
            dispatch(slice.actions.setCarePlanStep4Data(response.data.content));
        } else {
            dispatch(slice.actions.setCarePlanStep4Error(response.message));
        }
    };
}

export function getStep5Content(patientId, carePlanId) {
    return async () => {
        const response = await getCarePlanContent(patientId, carePlanId, 'step5');
        if (response.status === 200) {
            parseStep5DatesAsUTCWithoutTime(response.data.content);
            dispatch(slice.actions.setCarePlanStep5Data(response.data.content));
        } else {
            dispatch(slice.actions.setCarePlanStep5Error(response.message));
        }
    };
}

export function getStep6Content(patientId, carePlanId) {
    return async () => {
        const response = await getCarePlanContent(patientId, carePlanId, 'step6');
        if (response.status === 200) {
            parseStep6DatesAsUTCWithoutTime(response.data.content);
            dispatch(slice.actions.setCarePlanStep6Data(response.data.content));
        } else {
            dispatch(slice.actions.setCarePlanStep6Error(response.message));
        }
    };
}

export function getStep7Content(patientId, carePlanId) {
    return async () => {
        const response = await getCarePlanContent(patientId, carePlanId, 'step7');
        if (response.status === 200) {
            parseStep7DatesAsUTCWithoutTime(response.data.content);
            dispatch(slice.actions.setCarePlanStep7Data(response.data.content));
        } else {
            dispatch(slice.actions.setCarePlanStep7Error(response.message));
        }
    };
}

export function getStep8ContentAndDiagnoses(patientId, carePlanId) {
    return async () => {
        const diagnosesResponse = await getCarePlanContent(patientId, carePlanId, 'step2');
        const step8Response = await getCarePlanContent(patientId, carePlanId, 'step8');
        if (step8Response.status === 200 && diagnosesResponse.status === 200) {
            dispatch(
                slice.actions.setCarePlanStep8Data({
                    content: step8Response.data.content,
                    diagnoses: diagnosesResponse.data.content.diagnosesSelection
                })
            );
        } else {
            dispatch(slice.actions.setCarePlanStep8Error(step8Response.message || diagnosesResponse.message));
        }
    };
}

export function getStep9ContentAndDiagnoses(patientId, carePlanId) {
    return async () => {
        const diagnosesResponse = await getCarePlanContent(patientId, carePlanId, 'step2');
        const step9Response = await getCarePlanContent(patientId, carePlanId, 'step9');
        if (step9Response.status === 200 && diagnosesResponse.status === 200) {
            dispatch(
                slice.actions.setCarePlanStep9Data({
                    content: step9Response.data.content,
                    diagnoses: diagnosesResponse.data.content.diagnosesSelection
                })
            );
        } else {
            dispatch(slice.actions.setCarePlanStep9Error(step9Response.message || diagnosesResponse.message));
        }
    };
}

export function getStep10Content(patientId, carePlanId) {
    return async () => {
        const response = await getCarePlanContent(patientId, carePlanId, 'step10');
        if (response.status === 200) {
            parseStep10DatesAsUTCWithoutTime(response.data.content);
            dispatch(slice.actions.setCarePlanStep10Data(response.data.content));
        } else {
            dispatch(slice.actions.setCarePlanStep10Error(response.message));
        }
    };
}

export function getStep11Content(patientId, carePlanId) {
    return async () => {
        const response = await getCarePlanContent(patientId, carePlanId, 'step11');
        if (response.status === 200) {
            dispatch(slice.actions.setCarePlanStep11Data(response.data.content));
        } else {
            dispatch(slice.actions.setCarePlanStep11Error(response.message));
        }
    };
}

export function resetIndividualCarePlanState() {
    dispatch(slice.actions.resetIndividualCarePlanState());
}

export function resetStep1() {
    dispatch(slice.actions.resetStep1());
}

export function resetStep2() {
    dispatch(slice.actions.resetStep2());
}

export function resetStep3() {
    dispatch(slice.actions.resetStep3());
}

export function resetStep4() {
    dispatch(slice.actions.resetStep4());
}

export function resetStep5() {
    dispatch(slice.actions.resetStep5());
}

export function resetStep6() {
    dispatch(slice.actions.resetStep6());
}

export function resetStep7() {
    dispatch(slice.actions.resetStep7());
}

export function resetStep8() {
    dispatch(slice.actions.resetStep8());
}

export function resetStep9() {
    dispatch(slice.actions.resetStep9());
}

export function resetStep10() {
    dispatch(slice.actions.resetStep10());
}

export function resetStep11() {
    dispatch(slice.actions.resetStep11());
}
