// action - state management
import { LOGIN, LOGOUT } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    email: null,
    userId: 0,
    clinicGrants: {},
    organizationMeta: {},
    userMetadata: {
        isProviderAccount: false,
        providerId: null,
        firstName: '',
        lastName: ''
    }
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        // case REGISTER: {
        //     const { user } = action.payload;
        //     return {
        //         ...state,
        //         user
        //     };
        // }
        case LOGIN: {
            const { email, userId, clinicGrants, organizationMeta, userMetadata } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                userId,
                email,
                clinicGrants,
                organizationMeta,
                userMetadata
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                userId: 0,
                email: null,
                clinicGrants: {},
                organizationMeta: {},
                userMetadata: {
                    isProviderAccount: false,
                    providerId: null,
                    firstName: '',
                    lastName: ''
                }
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
