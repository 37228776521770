// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';

import { getProviderCareStatsForLoggedInUser, getCustomRangeProviderCareStatsForLoggedInUser } from '../../api/provider';
import { getClinicAssignedTaskCount } from '../../api/task';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    loading: true,
    email: null,
    userId: 0,
    userMetadata: {
        isProviderAccount: false,
        providerId: null,
        firstName: '',
        lastName: ''
    },
    /*
    clinicGrants: {
        1: {
            meta: {
                name: 'Clinic 1',
            },
            grants: {
                CLINIC_MANAGER: true,
            }
            ...
        },
        ...
    }
    organizationMeta: {
        meta: {
            id: 1,
            name: 'SynerGrx'
        }
        grants: {
            ORG_USER: true
        }
        ...
    ...
    }
    */
    clinicGrants: {},
    organizationMeta: {},
    // string id --> default to 0 to avoid writing non numbers for replacing id
    selectedClinic: '0',
    customRangeProviderCareStats: {
        loaded: false,
        error: false,
        needsRefresh: false,
        custom: {
            secondsLogged: 0
        }
    },
    providerCareStats: {
        loaded: false,
        error: false,
        today: {
            secondsLogged: 0
        },
        month: {
            patientsCaredFor: 0
        }
    },
    tasks: {
        loaded: false,
        error: false,
        activeAssignedToMe: {
            clinic: 0,
            protocol: 0,
            total: 0
        },
        countAssignedToMe: {
            clinic: 0,
            protocol: 0,
            total: 0
        },
        countAssignedToClinic: {
            clinic: 0,
            protocol: 0,
            total: 0
        },
        countAssignedToClinicProgrammatic: {}
    }
};

const slice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setAccount(state, action) {
            state.loading = false;
            state.email = action.payload.email;
            state.userId = action.payload.userId;
            state.clinicGrants = action.payload.clinicGrants;
            state.organizationMeta = action.payload.organizationMeta;
            state.userMetadata = action.payload.userMetadata;
        },
        setClinic(state, action) {
            state.selectedClinic = action.payload;
        },

        setProviderCareStats(state, action) {
            state.providerCareStats.loaded = true;
            state.providerCareStats.error = false;
            state.providerCareStats.today = action.payload.today;
            state.providerCareStats.month = action.payload.month;
        },
        setProviderCareStatsError(state, action) {
            state.providerCareStats.loaded = true;
            state.providerCareStats.error = action.payload;
        },

        setCustomRangeProviderCareStats(state, action) {
            state.customRangeProviderCareStats.loaded = true;
            state.customRangeProviderCareStats.error = false;
            state.customRangeProviderCareStats.needsRefresh = false;
            state.customRangeProviderCareStats.custom = action.payload.custom;
        },
        setCustomRangeProviderCareStatsLoading(state) {
            state.customRangeProviderCareStats.loaded = false;
            state.customRangeProviderCareStats.error = false;
            state.customRangeProviderCareStats.needsRefresh = false;
        },
        setCustomRangeProviderCareStatsError(state, action) {
            state.customRangeProviderCareStats.loaded = true;
            state.customRangeProviderCareStats.needsRefresh = false;
            state.customRangeProviderCareStats.error = action.payload;
        },
        setCustomRangeProviderCareStatsNeedsRefresh(state) {
            state.customRangeProviderCareStats.needsRefresh = true;
        },

        setTasksCount(state, action) {
            state.tasks.loaded = true;
            state.tasks.error = false;
            state.tasks.activeAssignedToMe = action.payload.activeAssignedToMe;
            state.tasks.countAssignedToMe = action.payload.countAssignedToMe;
            state.tasks.countAssignedToClinic = action.payload.countAssignedToClinic;
            state.tasks.countAssignedToClinicProgrammatic = action.payload.countAssignedToClinicProgrammatic;
        },

        setTasksError(state, action) {
            state.tasks.loaded = true;
            state.tasks.error = action.payload;
        },

        resetTaskState(state) {
            state.tasks = initialState.tasks;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setAccountData(email, userId, clinicGrants, organizationMeta, userMetadata) {
    return async () => {
        dispatch(
            slice.actions.setAccount({
                userId,
                email,
                clinicGrants,
                organizationMeta,
                userMetadata
            })
        );
    };
}

export function setClinic(clinic) {
    return async () => {
        dispatch(slice.actions.setClinic(clinic));
    };
}

export function getProviderCareStats() {
    return async () => {
        const response = await getProviderCareStatsForLoggedInUser();
        if (response.status === 200) {
            dispatch(slice.actions.setProviderCareStats(response.data));
        } else {
            dispatch(slice.actions.setProviderCareStatsError(response.message));
        }
    };
}

export function resetTaskState() {
    return dispatch(slice.actions.resetTaskState());
}

export function getClinicTaskCount(clinicId) {
    return async () => {
        const response = await getClinicAssignedTaskCount(clinicId);
        if (response.status === 200) {
            dispatch(slice.actions.setTasksCount(response.data));
        } else {
            dispatch(slice.actions.setTasksError(response.message));
        }
    };
}

export function getCustomRangeProviderCareStats(fromTS, toTS) {
    return async () => {
        dispatch(slice.actions.setCustomRangeProviderCareStatsLoading());
        const response = await getCustomRangeProviderCareStatsForLoggedInUser(fromTS, toTS);
        if (response.status === 200) {
            dispatch(slice.actions.setCustomRangeProviderCareStats(response.data));
        } else {
            dispatch(slice.actions.setCustomRangeProviderCareStatsError(response.message));
        }
    };
}

export function markRefreshNeededOfCustomProviderCareStats() {
    dispatch(slice.actions.setCustomRangeProviderCareStatsNeedsRefresh());
}
