// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
// import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    loading: true,
    providers: []
};

const slice = createSlice({
    name: 'providersList',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },

        // GET EVENTS
        getProvidersSuccess(state, action) {
            state.loading = false;
            state.providers = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProviders() {
    return async () => {
        try {
            // const response = await axios.get('/api/org-id/{orgId}/clinic-id/{clinicId}/patient/list');
            await new Promise((resolve) => setTimeout(resolve, 2000));
            dispatch(
                slice.actions.getProvidersSuccess([
                    { name: 'John Smith, MD', id: 23 },
                    { name: 'Jane Smith, PHD', id: 24 },
                    { name: 'Another Dr', id: 26 }
                ])
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
