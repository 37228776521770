// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import * as documentsAPI from 'api/documents';
import * as alertSnackbar from 'utils/snackbar-wrapper';

const initialState = {
    pagination: {
        pageNumber: 1,
        PAGE_SIZE: 10
    },
    patientDocumentsList: {
        error: false,
        loaded: false,
        data: []
    },
    meta: {
        error: false,
        count: 1
    },
    // once a file is uploaded - re run the list in order to show the new file
    uploadRequestedRefresh: false
};

const slice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        setPatientDocumentsListError(state) {
            state.patientDocumentsList.error = true;
            state.patientDocumentsList.loaded = true;
        },
        setPatientDocumentsList(state, action) {
            state.patientDocumentsList.loaded = true;
            state.patientDocumentsList.data = action.payload;
        },

        setPatientDocumentsLoadingAndReset(state) {
            state.patientDocumentsList = initialState.patientDocumentsList;
        },

        setPatientDocumentsCountError(state) {
            state.meta.error = true;
        },
        setPatientDocumentsCount(state, action) {
            state.meta.count = action.payload;
            state.meta.error = action.false;
        },

        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
        },

        resetState(state) {
            state.pagination = initialState.pagination;
            state.patientDocumentsList = initialState.patientDocumentsList;
            state.meta = initialState.meta;
            state.uploadRequestedRefresh = initialState.uploadRequestedRefresh;
        },

        setUploadTriggeredRefresh(state, action) {
            state.uploadRequestedRefresh = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function resetState() {
    dispatch(slice.actions.resetState());
}

export function getPatientDocumentsListAndSetPageNumber(patientId, pageSize, pageNumber, fileNameFilter) {
    return async () => {
        try {
            const listResponse = await documentsAPI.getDocumentList(patientId, pageSize, pageNumber, fileNameFilter);
            const pageCountResponse = await documentsAPI.getDocumentCount(patientId, fileNameFilter);
            dispatch(slice.actions.setPageNumber(pageNumber));
            dispatch(slice.actions.setPatientDocumentsList(listResponse.data.patientDocuments));
            dispatch(slice.actions.setPatientDocumentsCount(pageCountResponse.data.patientDocuments.count));
        } catch (error) {
            dispatch(alertSnackbar.error('Failed to load patient documents'));
            dispatch(slice.actions.setPatientDocumentsListError(error));
            dispatch(slice.actions.setPatientDocumentsCountError(error));
        }
    };
}

// on page number change set loading and unset data to show UI loading animation
export function setLoadingResetPatientDocumentsList() {
    dispatch(slice.actions.setPatientDocumentsLoadingAndReset());
}

export function setUploadTriggeredRefreshNeeded() {
    dispatch(slice.actions.setUploadTriggeredRefresh(true));
}

export function setUploadTriggeredRefreshComplete() {
    dispatch(slice.actions.setUploadTriggeredRefresh(false));
}
