import { openSnackbar } from 'store/slices/snackbar';

export function success(message) {
    return openSnackbar({
        open: true,
        message,
        variant: 'alert',
        alert: {
            color: 'success'
        }
    });
}

export function error(message) {
    return openSnackbar({
        open: true,
        message,
        variant: 'alert',
        alert: {
            color: 'error'
        }
    });
}

export function warning(message) {
    return openSnackbar({
        open: true,
        message,
        variant: 'alert',
        alert: {
            color: 'warning'
        }
    });
}

export function info(message) {
    return openSnackbar({
        open: true,
        message,
        variant: 'alert',
        alert: {
            color: 'info'
        }
    });
}
