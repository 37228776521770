// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import * as enrollmentAPI from 'api/enrollment';

const initialState = {
    ccm: {
        error: false,
        data: {
            type: 'ccm',
            enrollmentDate: null,
            cancellationDate: null,
            verbalAgreement: false,
            enrolledSameDayOfficeVisit: false,
            primaryClinician: null,
            specialist: null
        }
    },
    bhi: {
        error: false,
        data: {
            type: 'bhi',
            enrollmentDate: null,
            cancellationDate: null,
            verbalAgreement: false,
            primaryClinician: null,
            specialist: null
        }
    },
    rpm: {
        error: false,
        data: {
            type: 'rpm',
            enrollmentDate: null,
            cancellationDate: null,
            verbalAgreement: false,
            primaryClinician: null,
            specialist: null,
            equipmentSetupAndEducation: null
        }
    },
    currentEnrollments: {
        error: false,
        loaded: false,
        data: {
            ccm: false,
            bhi: false,
            rpm: false,
            pcm: false
        }
    },
    enrollmentDocumentStatus: {
        error: false,
        loaded: false,
        data: {
            infoDocStatus: null,
            agreementDocStatus: null,
            infoDocFile: null,
            agreementDocFile: null
        }
    }
};

const slice = createSlice({
    name: 'enrollment',
    initialState,
    reducers: {
        setCCMEnrollmentError(state) {
            state.ccm.error = true;
        },
        setCCMEnrollment(state, action) {
            state.ccm.data = action.payload;
        },
        setBHIEnrollmentError(state) {
            state.bhi.error = true;
        },
        setBHIEnrollment(state, action) {
            state.bhi.data = action.payload;
        },
        setRPMEnrollmentError(state) {
            state.rpm.error = true;
        },
        setRPMEnrollment(state, action) {
            state.rpm.data = action.payload;
        },
        setCurrentEnrollments(state, action) {
            state.currentEnrollments.data = action.payload;
            state.currentEnrollments.loaded = true;
        },
        setCurrentEnrollmentsError(state) {
            state.currentEnrollments.error = true;
            state.currentEnrollments.loaded = true;
        },

        resetState(state) {
            state.currentEnrollments = initialState.currentEnrollments;
            state.ccm = initialState.ccm;
            state.bhi = initialState.bhi;
            state.rpm = initialState.rpm;
        },

        setEnrollmentDocumentError(state) {
            state.enrollmentDocumentStatus.loaded = true;
            state.enrollmentDocumentStatus.error = true;
        },
        setEnrollmentDocumentStatus(state, action) {
            state.enrollmentDocumentStatus.loaded = true;
            state.enrollmentDocumentStatus.error = false;
            state.enrollmentDocumentStatus.data = action.payload;
        },
        resetEnrollmentDocumentStatus(state) {
            state.enrollmentDocumentStatus = initialState.enrollmentDocumentStatus;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function resetState() {
    return dispatch(slice.actions.resetState());
}

export function resetEnrollmentDocumentStatus() {
    return dispatch(slice.actions.resetEnrollmentDocumentStatus());
}

export function getCCMEnrollment(patientId) {
    return async () => {
        try {
            const response = await enrollmentAPI.getEnrollment(patientId, 'ccm');
            dispatch(slice.actions.setCCMEnrollment(response.data.enrollment));
        } catch (error) {
            dispatch(slice.actions.setCCMEnrollmentError(error));
        }
    };
}

export function getBHIEnrollment(patientId) {
    return async () => {
        try {
            const response = await enrollmentAPI.getEnrollment(patientId, 'bhi');
            dispatch(slice.actions.setBHIEnrollment(response.data.enrollment));
        } catch (error) {
            dispatch(slice.actions.setBHIEnrollmentError(error));
        }
    };
}

export function getRPMEnrollment(patientId) {
    return async () => {
        try {
            const response = await enrollmentAPI.getEnrollment(patientId, 'rpm');
            dispatch(slice.actions.setRPMEnrollment(response.data.enrollment));
        } catch (error) {
            dispatch(slice.actions.setRPMEnrollmentError(error));
        }
    };
}

export function getCurrentEnrollments(patientId) {
    return async () => {
        try {
            const response = await enrollmentAPI.getCurrentEnrollments(patientId);
            dispatch(slice.actions.setCurrentEnrollments(response.data.currentEnrollments));
        } catch (error) {
            dispatch(slice.actions.setCurrentEnrollmentsError(error));
        }
    };
}

export function getEnrollmentDocumentStatus(patientId, enrollmentType) {
    return async () => {
        try {
            const response = await enrollmentAPI.getEnrollmentDocumentStatus(patientId, enrollmentType);
            dispatch(slice.actions.setEnrollmentDocumentStatus(response.data));
        } catch (error) {
            dispatch(slice.actions.setEnrollmentDocumentError(error));
        }
    };
}
