import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'store';
import { useNavigate, useLocation } from 'react-router-dom';

import { TextField, MenuItem, InputAdornment } from '@mui/material';
import { IconBuildingHospital } from '@tabler/icons';
import { some } from 'lodash';

import { setClinic, resetTaskState as resetAccountTaskCountState } from 'store/slices/account';
import { resetState as resetPatientListState } from 'store/slices/patientList';
import { resetState as resetVitalsAlertsState } from 'store/slices/vitalsAlerts';
import { resetState as resetTaskState } from 'store/slices/task';
import { resetClinicMembersList } from 'store/slices/provider';
import { resetTemplateListState } from 'store/slices/protocolsInstance';
import { resetSelectedMenuItem } from 'store/slices/menu';

const GLOBAL_PAGES = [/^\/users/, /^\/user\/[0-9]+\/detail/, /^\/protocols\/template/, /^\/custom-programmatic-task/];

function getPageClinicChangeNavigationDestination(newClinicId, currentPath) {
    if (/\/clinic\/[0-9]+\/details/.test(currentPath)) {
        return `/clinic/${newClinicId}/details`;
    }
    if (/\/clinic\/[0-9]+\/reports/.test(currentPath)) {
        return `/clinic/${newClinicId}/reports`;
    }
    if (/\/clinic\/[0-9]+\/programmatic-tasks/.test(currentPath)) {
        return `/clinic/${newClinicId}/programmatic-tasks`;
    }

    return '/';
}

export default function ClinicSelection() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const accountData = useSelector((state) => state.account);
    const [onClinicLevelPage, setOnClinicLevelPage] = useState(true);

    const clinicList = Object.keys(accountData.clinicGrants).map((clinicId) => ({
        id: `${clinicId}`,
        name: accountData.clinicGrants[clinicId]?.meta?.name
    }));

    const handleChange = (event) => {
        if (event.target.value !== accountData.selectedClinic) {
            dispatch(setClinic(event.target.value));
            resetPatientListState();
            resetVitalsAlertsState();
            resetSelectedMenuItem();
            resetTaskState();
            resetAccountTaskCountState();
            resetTemplateListState();
            resetClinicMembersList();
            navigate(getPageClinicChangeNavigationDestination(event.target.value, location.pathname));
        }
    };

    useEffect(() => {
        setOnClinicLevelPage(!some(GLOBAL_PAGES, (page) => page.test(location.pathname)));
    }, [location.pathname]);

    return (
        <>
            {onClinicLevelPage && clinicList.length >= 1 && accountData.selectedClinic !== '0' && (
                <TextField
                    sx={{ marginRight: 5 }}
                    select
                    size="small"
                    value={accountData.selectedClinic}
                    onChange={handleChange}
                    label="Clinic Selection"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconBuildingHospital />
                            </InputAdornment>
                        )
                    }}
                >
                    {clinicList.map((clinic) => (
                        <MenuItem key={clinic.id} value={`${clinic.id}`}>
                            {' '}
                            {clinic.name}
                        </MenuItem>
                    ))}
                </TextField>
            )}
        </>
    );
}
