import axios from 'utils/axios';

export async function getProtocolsTemplateList() {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/protocols/template/list`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function createProtocolsTemplate() {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/protocols/template/create`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getProtocolsTemplate(templateId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/protocols/template/${templateId}`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}

export async function saveProtocolTemplate(templateId, name, enabled, content) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/protocols/template/${templateId}`, {
            name,
            enabled,
            content
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function createProtocolsInstance(patientId, templateId) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/protocols/create`, {
            templateId
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getEnabledProtocolsTemplateList() {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/protocols/template/list?enabled=true`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getProtocolsInstanceList(patientId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/protocols/list`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

// returns the template data and the instance data
export async function getProtocolsInstanceData(patientId, instanceId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/protocols/${instanceId}/load`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function saveProtocolsInstance(patientId, instanceId, status, content, assignedToProviderId) {
    try {
        const body = {
            status,
            content
        };
        if (assignedToProviderId) {
            body.assignedToProviderId = assignedToProviderId;
        }

        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/protocols/${instanceId}`, body);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}
