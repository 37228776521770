import axios from 'utils/axios';

export async function editProgrammaticTaskConfig(type, enabled, assignedToProviderId, customProgTaskDefId) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/settings/programmatic-task`, {
            type,
            enabled,
            assignedToProviderId,
            customProgTaskDefId
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function submitManualTriggerTask(type, customProgTaskDefId, previewRun) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/settings/programmatic-task/manual-trigger`, {
            type,
            customProgTaskDefId,
            previewRun
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getProgrammaticTaskPreviewStatus(previewRunId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/programmatic-task-preview/${previewRunId}/status`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}

export async function getProgrammaticTaskPreviewResults(previewRunId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/programmatic-task-preview/${previewRunId}/results`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}

export async function getProgrammaticTaskSettings(clinicId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/${clinicId}/settings/programmatic-task`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function editClinicUserSubmittedInfo(name, address) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/settings/user-submitted-info`, {
            name,
            address
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getClinicUserSubmittedInfo(clinicId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/${clinicId}/settings/user-submitted-info`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}
