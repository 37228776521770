import axios from 'utils/axios';

export async function postUserNew(data) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/user/new`, data);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function listUsers() {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/user/list`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getUserDetail(userId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/user/${userId}/detail`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function changePassword(userId, password) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/user/${userId}/change-password`, {
            password
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function changeClinicMapping(userId, clinicMapping, orgAdmin) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/user/${userId}/access-config-mapping`, {
            clinicMapping,
            orgAdmin
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function changeUserStatus(userId, disabled) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/user/${userId}/status`, {
            disabled
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}
