/*
 Checks for requested clinic grant or CLINIC_MANAGER or ORG_ADMIN
 example to check for CLINIC_MANAGER or ORG_ADMIN:
    hasClinicGrantOrHigherAccess(accountState, 'CLINIC_MANAGER')
*/
function hasClinicGrantOrHigherAccess(accountState, neededClinicGrant) {
    const {
        clinicGrants,
        selectedClinic,
        organizationMeta: { grants: orgGrants }
    } = accountState;

    // check if they have the correct clinic grant
    if (clinicGrants?.[selectedClinic]?.grants?.[neededClinicGrant]) {
        return true;
    }

    // check if they have clinic manager
    if (clinicGrants?.[selectedClinic]?.grants?.CLINIC_MANAGER) {
        return true;
    }

    // check if they have org admin
    if (orgGrants.ORG_ADMIN) {
        return true;
    }

    return false;
}

export function hasClinicManagerGrantOrHigherAccess(accountState) {
    return hasClinicGrantOrHigherAccess(accountState, 'CLINIC_MANAGER');
}

export function hasOrgAdmin(accountState) {
    const {
        organizationMeta: { grants: orgGrants }
    } = accountState;

    if (orgGrants?.ORG_ADMIN) {
        return true;
    }

    return false;
}
