import axios from 'utils/axios';

export async function getVitalsAlerts() {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/vitals/alerts`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function markAlertAsResolved(alertId) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/vitals/alerts/${alertId}/resolve`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getVitalsAlertsStats(clinicId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/${clinicId}/vitals/alerts/stats`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}
