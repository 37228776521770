import apothedocLogo from 'assets/images/apothedoc-full-logo.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => <img src={apothedocLogo} alt="Apothedoc" width="100" />;

export default Logo;
