import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'store';
import moment from 'moment';
import { isDate } from 'lodash';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Card,
    CardContent,
    Grid,
    LinearProgress,
    Button,
    Typography,
    linearProgressClasses,
    TextField,
    Box,
    CircularProgress
} from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';

import { getProviderCareStats, getCustomRangeProviderCareStats } from 'store/slices/account';
import { humanFormattedDecimalHours } from 'utils/humanSecondsFormatter';

const CARE_PLAN_TARGET_HOURS = 5.5;
const CARE_PLAN_TARGET_HOURS_FORMATTED = '5.50';

const MS_CONVERSION_TO_EOD_INCLUSIVE = 1000 * 60 * 60 * 24 - 1;

// styles
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 30,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.light : '#fff'
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main
    }
}));

const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    marginBottom: '22px',
    overflow: 'hidden',
    position: 'relative'
}));

/*
2 boxes --> today is just hours out of 6 with progress
the box below is just monthly number of touches
ORG LEVEL

show you havent logged any care today --> you havent cared for any patients this month
*/

// ==============================|| PROGRESS BAR WITH LABEL ||============================== //

function LinearProgressWithLabel({ hoursComplete, value, ...others }) {
    const theme = useTheme();

    return (
        <Grid container direction="column" spacing={1} sx={{ mt: 1.5 }}>
            <Grid item>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography
                            variant="caption"
                            sx={{ color: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary[800] }}
                        >
                            {/* Care session time logged <br /> */}
                            {hoursComplete} / {CARE_PLAN_TARGET_HOURS_FORMATTED} hours
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" color="inherit">{`${Math.round(value)}%`}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <BorderLinearProgress variant="determinate" value={value > 100 ? 100 : value} {...others} />
            </Grid>
        </Grid>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number,
    hoursComplete: PropTypes.number
};

// ==============================|| SIDEBAR - MENU CARD ||============================== //

const TodayCareStatsCards = () => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const startOfMonthDefault = moment().startOf('month');
    // use the beginning of the day like the mui picker
    const endOfMonthDefault = moment().endOf('month').startOf('day');

    const [customDateRange, setCustomDateRange] = useState(false);
    const [dateRange, setDateRange] = useState([startOfMonthDefault.toDate(), endOfMonthDefault.toDate()]);

    const setEnableCustomDateRange = () => {
        setCustomDateRange(true);
    };

    const setDisableCustomDateRange = () => {
        setCustomDateRange(false);
    };

    const handleDateSelectionChanged = (newValue) => {
        setDateRange(newValue);
    };

    const organizationMeta = useSelector((state) => state.account.organizationMeta);
    const accountData = useSelector((state) => state.account);

    useEffect(() => {
        dispatch(getProviderCareStats());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationMeta]);

    function refreshCustomRangeProviderCareStatsIfValid(start, end) {
        if (isDate(start) && isDate(end)) {
            dispatch(getCustomRangeProviderCareStats(start.getTime(), end.getTime() + MS_CONVERSION_TO_EOD_INCLUSIVE));
        }
    }

    useEffect(() => {
        refreshCustomRangeProviderCareStatsIfValid(dateRange?.[0], dateRange?.[1]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange, organizationMeta]);

    useEffect(() => {
        if (accountData.customRangeProviderCareStats.needsRefresh) {
            refreshCustomRangeProviderCareStatsIfValid(dateRange?.[0], dateRange?.[1]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountData.customRangeProviderCareStats.needsRefresh]);

    const patientsCaredFor = accountData.providerCareStats.month.patientsCaredFor;
    /* 
    seconds / 60 / 60 --> convert to hours
    hours / CARE_PLAN_TARGET_HOURS --> convert to percentage of target
    percentage --> 100 for display in progress bar
    */
    const percentageComplete = Math.round((accountData.providerCareStats.today.secondsLogged / 60 / 60 / CARE_PLAN_TARGET_HOURS) * 100);
    const hoursComplete = Math.round((accountData.providerCareStats.today.secondsLogged / 60 / 60) * 100) / 100;

    return (
        <>
            {accountData.providerCareStats.loaded && (
                <>
                    <CardStyle>
                        <CardContent sx={{ p: 2 }}>
                            <Grid item>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary[800]
                                    }}
                                >
                                    My logged care
                                </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start">
                                <Grid item sx={{ marginTop: 1, marginBottom: 1 }}>
                                    <Button
                                        disableElevation
                                        variant={!customDateRange ? 'contained' : 'text'}
                                        size="small"
                                        sx={{ color: 'inherit', '&.MuiButton-contained': { color: '#FFFFFF' } }}
                                        onClick={() => setDisableCustomDateRange()}
                                    >
                                        Today
                                    </Button>
                                    <Button
                                        disableElevation
                                        variant={customDateRange ? 'contained' : 'text'}
                                        size="small"
                                        sx={{ color: 'inherit', '&.MuiButton-contained': { color: '#FFFFFF' } }}
                                        onClick={() => setEnableCustomDateRange()}
                                    >
                                        Custom
                                    </Button>
                                </Grid>
                            </Grid>
                            {!customDateRange && <LinearProgressWithLabel hoursComplete={hoursComplete} value={percentageComplete} />}
                            {customDateRange && (
                                <>
                                    {accountData.customRangeProviderCareStats.loaded && (
                                        <>
                                            <Grid container justifyContent="flex-start" sx={{ marginTop: 1 }}>
                                                <Grid item>
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                        localeText={{ start: 'Start Date', end: 'End Date' }}
                                                    >
                                                        <DesktopDateRangePicker
                                                            value={dateRange}
                                                            onChange={() => {}}
                                                            onAccept={handleDateSelectionChanged}
                                                            renderInput={(startProps, endProps) => (
                                                                <>
                                                                    <TextField
                                                                        size="small"
                                                                        {...startProps}
                                                                        sx={{
                                                                            input: {
                                                                                paddingLeft: '6px !important',
                                                                                paddingRight: '6px !important'
                                                                            }
                                                                        }}
                                                                        inputProps={{ ...startProps.inputProps, readOnly: true }}
                                                                    />
                                                                    <br />
                                                                    <Box sx={{ mx: 1 }}> - </Box>
                                                                    <TextField
                                                                        size="small"
                                                                        {...endProps}
                                                                        sx={{
                                                                            input: {
                                                                                paddingLeft: '6px !important',
                                                                                paddingRight: '6px !important'
                                                                            }
                                                                        }}
                                                                        inputProps={{ ...endProps.inputProps, readOnly: true }}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    variant="subtitle1"
                                                    color={theme.palette.mode === 'dark' ? 'textSecondary' : 'grey.900'}
                                                    sx={{ opacity: theme.palette.mode === 'dark' ? 1 : 0.6, marginTop: 1 }}
                                                >
                                                    You have completed{' '}
                                                    {humanFormattedDecimalHours(
                                                        accountData.customRangeProviderCareStats.custom.secondsLogged
                                                    )}{' '}
                                                    for the select date range.
                                                </Typography>
                                            </Grid>
                                        </>
                                    )}

                                    {!accountData.customRangeProviderCareStats.loaded && <CircularProgress />}
                                </>
                            )}
                        </CardContent>
                    </CardStyle>
                    <CardStyle>
                        <CardContent sx={{ p: 2 }}>
                            <Grid item>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary[800]
                                    }}
                                >
                                    Monthly unique <br /> patient interactions
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="subtitle1"
                                    color={theme.palette.mode === 'dark' ? 'textSecondary' : 'grey.900'}
                                    sx={{ opacity: theme.palette.mode === 'dark' ? 1 : 0.6, marginTop: 1 }}
                                >
                                    You have cared for {patientsCaredFor} patients so far this month.
                                </Typography>
                            </Grid>
                        </CardContent>
                    </CardStyle>
                </>
            )}
        </>
    );
};

export default memo(TodayCareStatsCards);
